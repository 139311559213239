// In order to improve perceived performance, place any small critical scripts
// here so they will load sooner on slow connections (e.g. polyfills).
// DO NOT include any large libraries like jQuery, Backbone, or Underscore.

// console() polyfill for IE 9 (throws error when dev tools are closed)
import 'console-polyfill';

// Modernizr - https://modernizr.com/download?setclasses
// NOTE: Generate a custom build for each project based on what tests are needed.
import './lib/modernizr';

// Font face observer to detect when font files have loaded.
// Can drop once font-display is supported:
// http://caniuse.com/#feat=css-font-rendering-controls
import './modules/font-face';

// Detect input method in order to hide outlines in an accessible manner
// https://github.com/ten1seven/what-input
import 'what-input';

// Wrap tables to enable horizontal scrolling when viewport is too narrow
var tables = document.querySelectorAll('.u-richtext > table');
for (var i=0, len=tables.length; i<len; i++ ) {
  var el = tables[i];
  var wrapper = document.createElement('div');
  // Add “u-richtext” class since table styles are only applied to direct children (see _richtext.scss)
  wrapper.className = 'u-richtext js-tablewrap';
  // Allow scrollbar when needed
  wrapper.style.overflow = 'auto';
  // Set tabindex="0" for keyboard accessiblity
  // https://www.paciellogroup.com/blog/2016/02/short-note-on-improving-usability-of-scrollable-regions/
  wrapper.tabIndex = 0;
  el.parentNode.insertBefore(wrapper, el);
  wrapper.appendChild(el);
}
