//------------------------------------------------------------------------
// Setup Font Face Observer to check when fonts have loaded
// https://fontfaceobserver.com
// https://www.filamentgroup.com/lab/font-events.html
// https://jonsuh.com/blog/font-loading-with-font-events/
//------------------------------------------------------------------------
/* globals FontFaceObserver */// JSHint global vars

// CustomEvents polyfill
import 'custom-event-polyfill';

// Font face observer script allows us to detect when web fonts have been downloaded
import FontFaceObserver from 'fontfaceobserver';

// Only run if fonts have not been previously loaded
if ( 'sessionStorage' in window && !sessionStorage.fontsLoaded ) {
  var htmlEl = document.documentElement;

  // Create new instance of FontFaceObserver for each font file
  var stag = new FontFaceObserver('Stag', { weight: 400 });
  var stagLight = new FontFaceObserver('Stag', { weight: 300 });
  var stagSans = new FontFaceObserver('Stag Sans', { weight: 400 });
  var stagSansLight = new FontFaceObserver('Stag Sans', { weight: 300 });
  var stagSansSemibold = new FontFaceObserver('Stag Sans', { weight: 700 });

  // Promise polyfill provided by fontfaceobserver.js
  Promise.all([
    stag.load(),
    stagLight.load(),
    stagSans.load(),
    stagSansLight.load(),
    stagSansSemibold.load()
  ]).then(function() {
    // Add class
    htmlEl.className += ' fonts-loaded';

    // Set session var
    sessionStorage.fontsLoaded = true;

    // Emit font-loaded event
    if ( htmlEl.className.indexOf('lte-ie8') === -1 ) {
      var triggerEvent = function() {
        // CustomEvent requires custom-event-polyfill for IE9+ (no support in IE8-
        var fontEvent = new CustomEvent('fonts-loaded');
        htmlEl.dispatchEvent( fontEvent );
      };

      // Use setTimeout to allow browser to paint first
      window.setTimeout(triggerEvent, 0);
    }
  })
  .catch(function() {
    console.warn('Could not resolve Promise that webfonts loaded');
  });
}
